import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0 auto",
  },
}));

function Navbar({ appName }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {appName}
          </Typography>
          {/* <Typography>
            {displayUser && <span>Welcome, {displayUser}</span>}
          </Typography>
          {user && (
            <Button onClick={() => dispatch(logout(history))} color="inherit">
              Logout
            </Button>
          )} */}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar;
