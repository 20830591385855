import React from "react";
import { Switch, useHistory, useLocation, Route } from "react-router-dom";
import NotFoundRoute from "./NotFoundRoute";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Container from "@material-ui/core/Container";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

const useStyles = makeStyles((theme) => ({
  bottomNav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  container: {
    minHeight: `calc(100vh - 64px - 56px)`,
    marginBottom: "56px",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function AppShell({ children, navigation = [], redirects = [] }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <Container className={classes.container}>
        <Switch>
          {[...navigation, ...redirects].map(
            ({ path, Component, exact = false }) => (
              <Route key={path} exact={exact} path={path}>
                <Component />
              </Route>
            )
          )}

          <NotFoundRoute />
        </Switch>
      </Container>
      <BottomNavigation
        value={navigation.findIndex(
          (i) => location.pathname.indexOf(i.path) > -1
        )}
        onChange={(_, newValue) => {
          history.push(navigation[newValue].path);
        }}
        showLabels
        className={classes.bottomNav}
      >
        {navigation.map((n) => (
          <BottomNavigationAction key={n.path} label={n.label} icon={n.icon} />
        ))}
      </BottomNavigation>
    </>
  );
}

export default AppShell;
