import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import AppShell from "../common/components/AppShell";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CheckIcon from "@material-ui/icons/CheckCircle";
import LockIcon from "@material-ui/icons/Lock";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChildren,
  fetchCovidSubmissions,
  fetchTeachers,
  fetchTemperatures,
  fetchStaffTemperatures,
  fetchStaffCovidSubmissions,
  fetchSignouts,
} from "./teacher";
import Checkin from "./CheckIn";
import Manage from "./Manage";
import { selectIsAdmin } from "../common/slices/auth";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsPage from "../common/components/SettingsPage";
import AdminPage from "../admin/Admin";

const navigation = [
  {
    label: "Check In",
    path: "/checkin",
    icon: <CheckIcon />,
    Component: Checkin,
    exact: false, // because it has subroutes underneath
  },

  {
    label: "Manage",
    path: "/manage",
    icon: <GroupAddIcon />,
    Component: Manage,
    exact: false,
  },
  {
    path: "/settings",
    icon: <SettingsIcon />,
    label: "Settings",
    exact: true,
    Component: SettingsPage,
  },
];

const adminApp = {
  label: "Admin",
  path: "/admin",
  icon: <LockIcon />,
  Component: AdminPage,
  exact: true,
};

const redirects = [
  {
    label: null,
    path: "/",
    icon: null,
    Component: () => <Redirect to="/checkin" />,
    exact: true,
  },
];

function TeacherApp() {
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);

  useEffect(() => {
    dispatch(fetchChildren());
    dispatch(fetchCovidSubmissions());
    dispatch(fetchTeachers());
    dispatch(fetchTemperatures());
    dispatch(fetchStaffTemperatures());
    dispatch(fetchStaffCovidSubmissions());
    dispatch(fetchSignouts());
  }, [dispatch]);

  return (
    <AppShell
      navigation={isAdmin ? [...navigation, adminApp] : navigation}
      redirects={redirects}
    />
  );
}

export default TeacherApp;
