import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { API_BASE_URL } from "../../services/dataService";
import { normalize } from "../../services/utils";
import { addSnackbar } from "../common/slices/snackbar";

export const teacherCoursesSlice = createSlice({
  name: "teacher",
  initialState: {
    children: [],
    teachers: [],
    covidSubmissions: {},
    signouts: {},
    staffCovidSubmissions: {},
    temperatureSubmissions: {},
    staffTemperatureSubmissions: {},
  },
  reducers: {
    fetchChildrenSuccess: (state, action) => {
      state.children = action.payload;
    },
    fetchTeachersSuccess: (state, action) => {
      state.teachers = action.payload;
    },
    createChildSuccess: (state, action) => {
      state.children.push(action.payload);
    },
    createTemperatureSuccess: (state, action) => {
      state.temperatureSubmissions[action.payload.childId] = action.payload;
    },
    createCovidSubmissionSuccess: (state, action) => {
      state.covidSubmissions[action.payload.childId] = action.payload;
    },
    createSignoutSuccess: (state, action) => {
      state.signouts[action.payload.childId] = action.payload;
    },
    createStaffCovidSubmissionSuccess: (state, action) => {
      state.staffCovidSubmissions[action.payload.teacherId] = action.payload;
    },
    createStaffTemperatureSuccess: (state, action) => {
      state.staffTemperatureSubmissions[action.payload.teacherId] =
        action.payload;
    },
    deleteChildSuccess: (state, action) => {
      state.children = state.children.filter((i) => i.id !== action.payload);
    },
    fetchCovidSubmissionsSuccess: (state, action) => {
      state.covidSubmissions = action.payload;
    },
    fetchSignoutsSuccess: (state, action) => {
      state.signouts = action.payload;
    },
    fetchStaffCovidSubmissionsSuccess: (state, action) => {
      state.staffCovidSubmissions = action.payload;
    },
    fetchTemperatureSubmissionsSuccess: (state, action) => {
      state.temperatureSubmissions = action.payload;
    },
    fetchStaffTemperatureSubmissionsSuccess: (state, action) => {
      state.staffTemperatureSubmissions = action.payload;
    },
  },
});

export const {
  fetchChildrenSuccess,
  fetchTeachersSuccess,
  createChildSuccess,
  createTemperatureSuccess,
  createStaffTemperatureSuccess,
  createStaffCovidSubmissionSuccess,
  deleteChildSuccess,
  fetchCovidSubmissionsSuccess,
  fetchSignoutsSuccess,
  fetchStaffCovidSubmissionsSuccess,
  fetchTemperatureSubmissionsSuccess,
  fetchStaffTemperatureSubmissionsSuccess,
  createCovidSubmissionSuccess,
  createSignoutSuccess,
} = teacherCoursesSlice.actions;

export const fetchChildren = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/v1/teacher/children`);

    dispatch(fetchChildrenSuccess(data));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not fetch children";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const fetchTeachers = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/v1/teacher`);

    dispatch(fetchTeachersSuccess(data));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not fetch teachers";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const createChild = (params) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/v1/teacher/children`,
      params
    );

    dispatch(createChildSuccess(data));
    dispatch(addSnackbar({ message: "Child added", severity: "success" }));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not add child.  Please contact admin.";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const createTemperature = (params) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/v1/teacher/temperature`,
      params
    );

    dispatch(createTemperatureSuccess(data));
    dispatch(
      addSnackbar({ message: "Temperature recorded", severity: "success" })
    );
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not record temperature.  Please contact admin.";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const createStaffTemperature = (params) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/v1/teacher/stafftemperature`,
      params
    );

    dispatch(createStaffTemperatureSuccess(data));
    dispatch(
      addSnackbar({ message: "Temperature recorded", severity: "success" })
    );
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not record temperature.  Please contact admin.";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const deleteChild = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_BASE_URL}/v1/teacher/children?childId=${id}`);

    dispatch(deleteChildSuccess(id));
    dispatch(addSnackbar({ message: "Child removed", severity: "success" }));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not delete child.  Please contact admin.";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const fetchCovidSubmissions = (date) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/v1/teacher/covid?date=${
        date || moment().format("YYYY-MM-DD")
      }`
    );

    dispatch(fetchCovidSubmissionsSuccess(normalize(data, "childId")));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not fetch covid submissions";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const fetchSignouts = (date) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/v1/teacher/child-signout?date=${
        date || moment().format("YYYY-MM-DD")
      }`
    );

    dispatch(fetchSignoutsSuccess(normalize(data, "childId")));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not fetch signouts";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const fetchStaffCovidSubmissions = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/v1/teacher/staffcovid`);

    dispatch(fetchStaffCovidSubmissionsSuccess(normalize(data, "teacherId")));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not fetch covid submissions";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const fetchTemperatures = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/v1/teacher/temperature`);

    dispatch(fetchTemperatureSubmissionsSuccess(normalize(data, "childId")));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not fetch temperature submissions";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const fetchStaffTemperatures = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/v1/teacher/stafftemperature`
    );

    dispatch(
      fetchStaffTemperatureSubmissionsSuccess(normalize(data, "teacherId"))
    );
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not fetch temperature submissions";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const createStaffCovidSubmission = (params) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/v1/teacher/staffcovid`,
      params
    );

    dispatch(createStaffCovidSubmissionSuccess(data));
    dispatch(addSnackbar({ message: "Success!", severity: "success" }));
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Could not submit form.  Please contact admin.";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const createCovidSubmission =
  (params, onComplete) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${API_BASE_URL}/v1/teacher/covid`,
        params
      );

      dispatch(createCovidSubmissionSuccess(data));
      dispatch(addSnackbar({ message: "Success!", severity: "success" }));
      onComplete();
    } catch (e) {
      const message =
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : "Something went wrong";
      dispatch(addSnackbar({ message, severity: "error" }));
    }
  };

export const createChildSignout = (params, onComplete) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/v1/teacher/child-signout`,
      params
    );

    dispatch(createSignoutSuccess(data));
    dispatch(addSnackbar({ message: "Success!", severity: "success" }));
    onComplete();
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Something went wrong";
    dispatch(addSnackbar({ message, severity: "error" }));
  }
};

export const selectChildren = (state) => state.teacher.children;
export const selectTeachers = (state) => state.teacher.teachers;
export const selectCovid = (state) => state.teacher.covidSubmissions;
export const selectStaffCovid = (state) => state.teacher.staffCovidSubmissions;
export const selectTemperatures = (state) =>
  state.teacher.temperatureSubmissions;
export const selectStaffTemperatures = (state) =>
  state.teacher.staffTemperatureSubmissions;
export const selectChildSignouts = (state) => state.teacher.signouts;

export default teacherCoursesSlice.reducer;
