import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL, apiStatuses } from "../../../services/dataService";
import { addSnackbar } from "./snackbar";

const { LOADING, ERROR, RESTING, SUCCESS } = apiStatuses;

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    status: LOADING,
    errorMessage: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.status = SUCCESS;
      state.user = action.payload;
      state.errorMessage = null;
    },
    loginError: (state, action) => {
      state.status = ERROR;
      state.errorMessage = action.payload;
    },
    loginLoading: (state) => {
      state.status = LOADING;
      state.errorMessage = null;
    },
    logoutSuccess: (state) => {
      state.user = null;
      state.status = RESTING;
    },
  },
});

export const {
  loginSuccess,
  loginError,
  loginLoading,
  logoutSuccess,
} = authSlice.actions;

export const login = (params, history) => async (dispatch) => {
  try {
    dispatch(loginLoading());
    const { data } = await axios.post(`${API_BASE_URL}/v1/auth/login`, params);

    dispatch(loginSuccess(data));
    dispatch(
      addSnackbar({
        severity: "success",
        message: `Welcome ${data.firstName}`,
        duration: 2000,
      })
    );
    history.push("/");
  } catch (e) {
    dispatch(
      loginError(
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : "Could not login"
      )
    );
  }
};

export const logout = (history) => async (dispatch) => {
  try {
    dispatch(loginLoading());
    await axios.post(`${API_BASE_URL}/v1/auth/logout`);
    dispatch(logoutSuccess());
    history.push("/login");
    dispatch(addSnackbar({ severity: "success", message: "See ya next time" }));
  } catch (e) {
    //TODO handle logout error
    dispatch(logoutSuccess());
  }
};

export const me = (slug) => async (dispatch) => {
  try {
    dispatch(loginLoading());
    const { data } = await axios.get(`${API_BASE_URL}/v1/auth/me?slug=${slug}`);
    dispatch(loginSuccess(data));
  } catch (e) {
    axios.post(`${API_BASE_URL}/v1/auth/logout`);
    dispatch(logoutSuccess());
  }
};

export const selectUser = (state) => state.auth.user;
export const selectIsAdmin = (state) =>
  state.auth.user &&
  state.auth.user.roles &&
  state.auth.user.roles.indexOf("admin") > -1;
export const selectUserRoles = (state) => {
  if (state.auth.user) {
    return state.auth.user.roles;
  }
  return [];
};
export const selectIsLoading = (state) => state.auth.status === LOADING;
export const selectErrorMessage = (state) => state.auth.errorMessage;

export default authSlice.reducer;
