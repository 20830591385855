import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { addSnackbar } from "../common/slices/snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import BasicTextInput from "../common/components/BasicTextInput";
import BasicSelect from "../common/components/BasicSelect";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { createChildSignout, selectChildren } from "./teacher";
import { alphabeticSort } from "../../services/utils";

const useStyles = makeStyles((theme) => ({
  heading: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },

  label: {
    fontSize: theme.typography.pxToRem(12),
  },
  checkFormRow: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  successContainer: {
    textAlign: "center",
    paddingTop: theme.spacing(3),
    "& button": {
      marginTop: theme.spacing(1),
    },
  },
}));

function ParentSignout() {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, control, reset } = useForm();
  const classes = useStyles();
  const children = useSelector(selectChildren);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    dispatch(
      createChildSignout(data, () => {
        dispatch(
          addSnackbar({
            severity: "success",
            message: "Success! You're all set",
          })
        );
        reset({
          childId: null,
          parentName: null,
        });

        setSuccess(true);
      })
    );
  };

  return (
    <div>
      <Grid container style={{ height: "calc(100vh - 64px)" }}>
        <Grid item xs={1} md={4} />
        <Grid item xs={10} md={4}>
          {children.length > 0 && !success && (
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Typography
                className={classes.heading}
                align="center"
                variant="h6"
              >
                Sign Out
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Date: {moment().format("dddd, MMMM DD, YYYY")}
              </Typography>

              <BasicSelect
                className={classes.m0}
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                name="childId"
                label="Child Name"
                labelId="child-name-label"
                selectId="child-name-select"
                error={!!errors.childId}
                options={children
                  .slice()
                  .sort(alphabeticSort("name"))
                  .map((c) => ({ label: c.name, value: c.id }))}
              />

              <BasicTextInput
                id="outlined-parent-name-input"
                label="Parent or Guardian Full Name"
                type="text"
                name="parentName"
                variant="outlined"
                fullWidth
                error={!!errors.parentName}
                ref={register({ required: true })}
                helperText={errors.parentName && "Required"}
              />

              <Button
                style={{ width: "100%" }}
                variant="contained"
                type="submit"
                color="primary"
              >
                Sign Out
              </Button>
            </form>
          )}

          {success && (
            <div className={classes.successContainer}>
              <Typography variant="h6">
                Thanks for signing out. See you soon!
              </Typography>
              <Button
                onClick={() => setSuccess(false)}
                color="primary"
                variant="outlined"
              >
                Go Back
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ParentSignout;
