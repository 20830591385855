import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector } from "react-redux";
import { selectTeachers } from "./teacher";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { alphabeticSort } from "../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },
}));

function Staff() {
  const classes = useStyles();

  const teachers = useSelector(selectTeachers);

  return (
    <>
      <div className={classes.root}>
        <Table className={classes.table} aria-label="staff management table">
          <TableHead>
            <TableRow>
              <TableCell>Staff Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers
              .slice()
              .sort(alphabeticSort("firstName"))
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {`${row.firstName} ${row.lastName}`}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

export default Staff;
