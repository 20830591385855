import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Children from "./Children";
import Staff from "./Staff";
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  Route,
  Redirect,
} from "react-router-dom";

function Manage() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
    if (newValue === 0) {
      history.push(`${path}/children`);
    }
    if (newValue === 1) {
      history.push(`${path}/staff`);
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf("staff") > -1) {
      setActiveTabIndex(1);
    }
    if (location.pathname.indexOf("children") > -1) {
      setActiveTabIndex(0);
    }
  }, [location]);

  return (
    <>
      <Paper>
        <Tabs
          value={activeTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Children"></Tab>
          <Tab label="Staff" />
        </Tabs>
      </Paper>
      <div>
        <Switch>
          <Redirect exact path={path} to={`${path}/children`} />
          <Route exact path={`${path}/children`}>
            <Children />
          </Route>
          <Route exact path={`${path}/staff`}>
            <Staff />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default Manage;
