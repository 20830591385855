import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChildCheckin from "./ChildCheckIn";
import StaffCheckin from "./StaffCheckIn";
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  Route,
  Redirect,
} from "react-router-dom";
import ParentSignin from "./ParentSignin";
import ParentSignout from "./ParentSignout";

function Checkin() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
    if (newValue === 0) {
      history.push(`${path}/sign-in`);
    }
    if (newValue === 1) {
      history.push(`${path}/sign-out`);
    }
    if (newValue === 2) {
      history.push(`${path}/children`);
    }
    if (newValue === 3) {
      history.push(`${path}/staff`);
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf("sign-in") > -1) {
      setActiveTabIndex(0);
    }
    if (location.pathname.indexOf("sign-out") > -1) {
      setActiveTabIndex(1);
    }
    if (location.pathname.indexOf("children") > -1) {
      setActiveTabIndex(2);
    }
    if (location.pathname.indexOf("staff") > -1) {
      setActiveTabIndex(3);
    }
  }, [location]);

  return (
    <>
      <Paper>
        <Tabs
          value={activeTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Sign In"></Tab>
          <Tab label="Sign Out"></Tab>
          <Tab label="Summary"></Tab>
          <Tab label="Staff" />
        </Tabs>
      </Paper>
      <div>
        <Switch>
          <Redirect exact path={path} to={`${path}/sign-in`} />
          <Route exact path={`${path}/children`}>
            <ChildCheckin />
          </Route>
          <Route exact path={`${path}/sign-in`}>
            <ParentSignin />
          </Route>

          <Route exact path={`${path}/sign-out`}>
            <ParentSignout />
          </Route>
          <Route exact path={`${path}/staff`}>
            <StaffCheckin />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default Checkin;
