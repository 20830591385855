import React from "react";
import { useForm } from "react-hook-form";
import BasicTextInput from "../components/BasicTextInput";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { EMAIL_REGEX } from "../../../services/constants";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  heading1: { marginBottom: theme.spacing(2), fontWeight: "bold" },
  heading2: { marginBottom: theme.spacing(2) },
}));

function LoginForm({ onSubmit, className, errorMessage = "" }) {
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.heading1} align="center" variant="h4">
        Preschool Portal
      </Typography>
      <Typography
        className={classes.heading2}
        variant="subtitle1"
        display="block"
        align="center"
      >
        Login
      </Typography>
      <BasicTextInput
        id="outlined-email-input"
        label="Email"
        type="text"
        name="email"
        autoComplete="email"
        variant="outlined"
        fullWidth
        error={!!errors.email}
        ref={register({ required: true, pattern: EMAIL_REGEX })}
        helperText={errors.email && "Valid email required"}
      />
      <BasicTextInput
        id="outlined-password-input"
        label="Password"
        type="password"
        name="password"
        autoComplete="current-password"
        variant="outlined"
        fullWidth
        error={!!errors.password}
        ref={register({ required: true })}
        helperText={errors.password && "Required"}
      />
      {errorMessage && (
        <Typography variant="subtitle2" color="error">
          {errorMessage}
        </Typography>
      )}
      <Button
        style={{ width: "100%" }}
        variant="contained"
        type="submit"
        color="primary"
      >
        Login
      </Button>
    </form>
  );
}

export default LoginForm;
