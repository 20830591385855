import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { API_BASE_URL } from "../../services/dataService";
import axios from "axios";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { selectUser } from "../common/slices/auth";

const useStyles = makeStyles((theme) => ({
  heading: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },

  label: {
    fontSize: theme.typography.pxToRem(12),
  },
  checkFormRow: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  successContainer: {
    textAlign: "center",
    paddingTop: theme.spacing(3),
    "& button": {
      marginTop: theme.spacing(1),
    },
  },
}));

function TeacherCovidForm({ onSubmit }) {
  const { handleSubmit, control } = useForm();
  const classes = useStyles();
  const [covid, setCovid] = useState({});
  const { firstName, lastName } = useSelector(selectUser);
  const acceptanceLabel = `I, ${firstName} ${lastName}, certify that the above statements are true`;

  useEffect(() => {
    axios.get(`${API_BASE_URL}/v1/public/covidtext?type=adult`).then((res) => {
      setCovid({ content: res.data.content, id: res.data.id });
    });
  }, []);

  return (
    <div>
      <Grid container style={{ height: "calc(100vh - 64px)" }}>
        <Grid item xs={1} md={4} />
        <Grid item xs={10} md={4}>
          {covid.content && (
            <form
              className={classes.form}
              onSubmit={handleSubmit(
                onSubmit({
                  acceptedText: acceptanceLabel,
                  covidContentId: covid.id,
                })
              )}
            >
              <Typography className={classes.secondaryHeading}>
                Date: {moment().format("dddd, MMMM DD, YYYY")}
              </Typography>

              <div dangerouslySetInnerHTML={{ __html: covid.content }}></div>

              <FormGroup row className={classes.checkFormRow}>
                <Controller
                  control={control}
                  name="acceptedText"
                  defaultValue={false}
                  rules={{ required: true }}
                  render={({ onChange, onBlur, value, name }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.checked)}
                            checked={value}
                            className={classes.checkbox}
                          />
                        }
                        label={
                          <span className={classes.label}>
                            {acceptanceLabel}
                          </span>
                        }
                      />
                    );
                  }}
                ></Controller>
              </FormGroup>

              <Button
                style={{ width: "100%" }}
                variant="contained"
                type="submit"
                color="primary"
              >
                Submit
              </Button>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default TeacherCovidForm;
