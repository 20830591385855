import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    current: null,
  },
  reducers: {
    addSnackbar: (state, action) => {
      const { severity = "success", message, duration = 3000 } = action.payload;
      state.current = {
        severity,
        message,
        duration,
      };
    },
    removeSnackbar: (state, action) => {
      state.current = null;
    },
  },
});

export const { addSnackbar, removeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
