import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./apps/common/slices/auth";
import snackbarReducer from "./apps/common/slices/snackbar";
import teacherReducer from "./apps/teacher/teacher";

export default configureStore({
  reducer: {
    auth: authReducer,
    teacher: teacherReducer,
    snackbar: snackbarReducer,
  },
});
