import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
}));

const BasicTextInput = React.forwardRef(({ className = "", ...rest }, ref) => {
  const classes = useStyles();

  return (
    <TextField
      className={`${className} ${classes.root}`}
      {...rest}
      inputRef={ref}
    />
  );
});

export default BasicTextInput;
