import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import LoginPage from "./LoginPage";
import CovidForm from "./CovidForm";
import NotFoundRoute from "../common/components/NotFoundRoute";

function UnauthenticatedApp({ slug }) {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login">
        <LoginPage slug={slug} />
      </Route>
      <Route exact path="/covid">
        <CovidForm />
      </Route>
      <NotFoundRoute />
    </Switch>
  );
}

export default UnauthenticatedApp;
