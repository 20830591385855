import React from "react";
import UserBadge from "./UserBadge";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LockIcon from "@material-ui/icons/LockOpen";
import Button from "@material-ui/core/Button";
import { logout } from "../slices/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ChangePasswordForm from "./ChangePasswordForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordionSummary-root": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  logoutButton: {
    margin: `${theme.spacing(3)}px auto`,
    display: "block",
  },
}));

function SettingsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <UserBadge />
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <LockIcon style={{ marginRight: 10 }} />
            <Typography className={classes.heading}>Change Password</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChangePasswordForm />
          </AccordionDetails>
        </Accordion>
      </div>

      <Button
        color="primary"
        className={classes.logoutButton}
        align="center"
        variant="text"
        onClick={() => dispatch(logout(history))}
      >
        Logout
      </Button>
    </>
  );
}

export default SettingsPage;
