import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 350,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase({
  className,
  placeholder,
  style = {},
  value = "",
  onChange = () => {},
  onClear = () => {},
}) {
  const classes = useStyles();

  return (
    <Paper
      component="form"
      className={`${classes.root} ${className || ""}`}
      style={style}
    >
      <IconButton className={classes.iconButton} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={placeholder || ""}
        inputProps={{ onChange, value }}
      />
      <IconButton
        onClick={onClear}
        color="primary"
        className={classes.iconButton}
      >
        <ClearIcon />
      </IconButton>
    </Paper>
  );
}
