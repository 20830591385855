export const initUpper = (str) => {
  const [first, ...rest] = str;
  return `${first.toUpperCase()}${rest.map((i) => i.toLowerCase()).join("")}`;
};

export const normalize = (arr, field = "id") =>
  arr.reduce((acc, curr) => {
    acc[curr[field]] = curr;
    return acc;
  }, {});

export const alphabeticSort = (field) => (a, b) =>
  a[field].localeCompare(b[field]);
