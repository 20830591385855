import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  heading: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },

  label: {
    fontSize: theme.typography.pxToRem(12),
  },
  checkFormRow: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  successContainer: {
    textAlign: "center",
    paddingTop: theme.spacing(3),
    "& button": {
      marginTop: theme.spacing(1),
    },
  },
}));

function CovidForm() {
  const classes = useStyles();
  return (
    <div>
      <Grid container style={{ height: "calc(100vh - 64px)" }}>
        <Grid item xs={1} md={4} />
        <Grid item xs={10} md={4}>
          <form className={classes.form}>
            <Typography className={classes.heading} align="center" variant="h6">
              You will now sign your child in using the tablet at the school.
              Thank you!
            </Typography>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default CovidForm;
