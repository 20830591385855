import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackbar } from "../slices/snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackbarContainer() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const current = useSelector((state) => state.snackbar.current);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(!!current);
  }, [current]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(removeSnackbar());
  };

  return (
    <div className={classes.root}>
      {current && (
        <Snackbar
          open={open}
          autoHideDuration={current.duration}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={current.severity}>
            {current.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
