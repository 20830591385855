import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import BasicTextInput from "../common/components/BasicTextInput";
import BasicSelect from "../common/components/BasicSelect";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { API_BASE_URL } from "../../services/dataService";
import axios from "axios";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { createCovidSubmission, selectChildren } from "./teacher";
import { alphabeticSort } from "../../services/utils";

const useStyles = makeStyles((theme) => ({
  heading: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },

  label: {
    fontSize: theme.typography.pxToRem(12),
  },
  checkFormRow: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginBottom: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  successContainer: {
    textAlign: "center",
    paddingTop: theme.spacing(3),
    "& button": {
      marginTop: theme.spacing(1),
    },
  },
}));

function ParentSignin() {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, control, watch, reset } = useForm();
  const classes = useStyles();
  const [covid, setCovid] = useState({});
  const children = useSelector(selectChildren);
  const [success, setSuccess] = useState(false);

  const parentName = watch("parentName");
  const childId = watch("childId");
  const childName =
    childId && children.length > 0
      ? children.find((c) => c.id === childId).name
      : "";
  const acceptanceLabel = `I, ${
    parentName || ""
  }, am the parent or guardian of ${childName}, and I certify that the above statements are true`;

  useEffect(() => {
    axios.get(`${API_BASE_URL}/v1/public/covidtext?type=minor`).then((res) => {
      setCovid({ content: res.data.content, id: res.data.id });
    });
  }, []);

  const onSubmit = async (data) => {
    const dataToSend = {
      ...data,
      acceptedText: acceptanceLabel,
      covidContentId: covid.id,
    };

    dispatch(
      createCovidSubmission(dataToSend, () => {
        reset({
          acceptedText: false,
          childId: null,
          parentName: null,
        });

        setSuccess(true);
      })
    );
  };

  return (
    <div>
      <Grid container style={{ height: "calc(100vh - 64px)" }}>
        <Grid item xs={1} md={4} />
        <Grid item xs={10} md={4}>
          {covid.content && children.length > 0 && !success && (
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Typography
                className={classes.heading}
                align="center"
                variant="h6"
              >
                Covid Questionnaire
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Date: {moment().format("dddd, MMMM DD, YYYY")}
              </Typography>

              <BasicSelect
                className={classes.m0}
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                name="childId"
                label="Child Name"
                labelId="child-name-label"
                selectId="child-name-select"
                error={!!errors.childId}
                options={children
                  .slice()
                  .sort(alphabeticSort("name"))
                  .map((c) => ({ label: c.name, value: c.id }))}
              />

              <BasicTextInput
                id="outlined-parent-name-input"
                label="Parent or Guardian Full Name"
                type="text"
                name="parentName"
                variant="outlined"
                fullWidth
                error={!!errors.parentName}
                ref={register({ required: true })}
                helperText={errors.parentName && "Required"}
              />

              <div dangerouslySetInnerHTML={{ __html: covid.content }}></div>

              <FormGroup row className={classes.checkFormRow}>
                <Controller
                  control={control}
                  name="acceptedText"
                  defaultValue={false}
                  rules={{ required: true }}
                  render={({ onChange, onBlur, value, name }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.checked)}
                            checked={value}
                            className={classes.checkbox}
                          />
                        }
                        label={
                          <span className={classes.label}>
                            {acceptanceLabel}
                          </span>
                        }
                      />
                    );
                  }}
                ></Controller>
              </FormGroup>

              <Button
                style={{ width: "100%" }}
                variant="contained"
                type="submit"
                color="primary"
              >
                Sign In
              </Button>
            </form>
          )}

          {success && (
            <div className={classes.successContainer}>
              <Typography variant="h6">
                Thanks for submitting the form!
              </Typography>
              <Button
                onClick={() => setSuccess(false)}
                color="primary"
                variant="outlined"
              >
                Go Back
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ParentSignin;
