import React from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "../common/components/LoginForm";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { login, selectErrorMessage } from "../common/slices/auth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loginForm: { paddingBottom: theme.spacing(5) },
}));

function LoginPage({ slug }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const errorMessage = useSelector(selectErrorMessage);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        style={{ height: "calc(100vh - 64px)" }}
      >
        <Grid item xs={1} md={4} />
        <Grid item xs={10} md={4}>
          <LoginForm
            className={classes.loginForm}
            onSubmit={(data) => dispatch(login({ ...data, slug }, history))}
            errorMessage={errorMessage}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginPage;
