import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { selectUser } from "../slices/auth";
import { useSelector } from "react-redux";
import { initUpper } from "../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  subheader: {
    color: theme.palette.grey[600],
  },
}));

function UserBadge(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      p={1}
      className={classes.root}
    >
      <Avatar className={classes.orange}>
        {user.firstName[0].toUpperCase()}
        {user.lastName[0].toUpperCase()}
      </Avatar>
      <Box>
        <Typography variant="h6">
          {user.firstName} {user.lastName}
        </Typography>
        <Typography className={classes.subheader} variant="subtitle1">
          {initUpper(user.roles[0])}
        </Typography>
      </Box>
    </Box>
  );
}

export default UserBadge;
