import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import { useDispatch, useSelector } from "react-redux";
import {
  selectChildren,
  selectCovid,
  fetchCovidSubmissions,
  fetchSignouts,
  selectTeachers,
  createTemperature,
  selectChildSignouts,
} from "./teacher";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "react-hook-form";
import BasicTextInput from "../common/components/BasicTextInput";
import BasicSelect from "../common/components/BasicSelect";
import MomentUtils from "@date-io/moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import RefreshIcon from "@material-ui/icons/RefreshOutlined";
import { selectUser } from "../common/slices/auth";
import { alphabeticSort } from "../../services/utils";
import SearchFilter from "../common/components/SearchFilter";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "bold",
    textAlign: "center",
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },

  createButton: {
    position: "fixed",
    bottom: "64px",
    right: theme.spacing(2),
  },
  m0: {},
  successIcon: {
    color: theme.palette.success.dark,
    verticalAlign: "middle",
  },
  warningIcon: {
    color: theme.palette.warning.dark,
    verticalAlign: "middle",
  },
  vim: {
    verticalAlign: "middle",
  },
  takenBy: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
  },
  childTemperatureLabel: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  table: {
    marginBottom: theme.typography.pxToRem(46),
  },
  successRow: {
    backgroundColor: "#e1f0e2",
  },
  filterBox: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      maxWidth: 400,
    },
    maxWidth: 200,
  },
  todayButton: {
    textAlign: "center",
    color: theme.palette.grey[700],
  },
}));

function ChildCheckin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();
  const [open, setOpen] = useState(false);
  const [childForTemperature, setChildForTemperature] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [selectedDate, handleDateChange] = useState(new Date());
  const [dateForFetch, setDateForFetch] = useState(null);

  const children = useSelector(selectChildren);
  const covidSubmissions = useSelector(selectCovid);
  const signouts = useSelector(selectChildSignouts);
  const teachers = useSelector(selectTeachers);
  const loggedInUser = useSelector(selectUser);

  const handleClose = () => {
    setChildForTemperature(null);
    setOpen(false);
  };

  const handleSubmitTemperature = (data) => {
    dispatch(createTemperature({ ...data, childId: childForTemperature.id }));
    setChildForTemperature(null);
    setOpen(false);
  };

  const refresh = useCallback(() => {
    dispatch(fetchCovidSubmissions(dateForFetch));
    dispatch(fetchSignouts(dateForFetch));
  }, [dispatch, dateForFetch]);

  const getCurrentTeacher = () => {
    const currentTeacher = teachers.find(
      (t) =>
        t.firstName === loggedInUser.firstName &&
        t.lastName === loggedInUser.lastName
    );
    return currentTeacher ? currentTeacher.id : teachers[0].id;
  };

  useEffect(() => {
    setDateForFetch(moment(selectedDate).format("YYYY-MM-DD"));
  }, [selectedDate]);

  useEffect(() => {
    refresh();
  }, [refresh, dateForFetch]);

  return (
    <>
      <div className={classes.root}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={classes.secondaryHeading}>
            <DatePicker
              maxDate={moment()}
              inputVariant="outlined"
              value={selectedDate}
              onChange={handleDateChange}
            />
            <SearchFilter
              onChange={(e) => setFilterText(e.target.value)}
              value={filterText}
              className={classes.filterBox}
              placeholder="Find Child"
              onClear={() => setFilterText("")}
            />
          </div>
          {dateForFetch !== moment().format("YYYY-MM-DD") && (
            <div className={classes.todayButton}>
              *You are viewing a previous day
              <Button
                size="small"
                color="primary"
                variant="text"
                onClick={() => handleDateChange(moment())}
              >
                Go to Today
              </Button>
            </div>
          )}
        </MuiPickersUtilsProvider>
        <Table className={classes.table} aria-label="check in management table">
          <TableHead>
            <TableRow>
              <TableCell>Child Name</TableCell>
              <TableCell align="center">Signed In</TableCell>
              <TableCell align="center">Signed Out</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.length > 0 &&
              children.length > 0 &&
              children
                .slice()
                .filter(
                  (c) =>
                    c.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1
                )
                .sort(alphabeticSort("name"))
                .map((row) => (
                  <TableRow
                    key={row.id}
                    className={
                      !!covidSubmissions[row.id] && !!signouts[row.id]
                        ? classes.successRow
                        : ""
                    }
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">
                      {!!covidSubmissions[row.id] &&
                        covidSubmissions[row.id].time}
                      {!covidSubmissions[row.id] && (
                        <WarningIcon className={classes.warningIcon} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {!!signouts[row.id] && signouts[row.id].time}
                      {!signouts[row.id] && (
                        <WarningIcon className={classes.warningIcon} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>

      <Fab
        onClick={refresh}
        color="primary"
        className={classes.createButton}
        variant="extended"
      >
        <RefreshIcon />
        Refresh
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="temperature-dialog-title"
        aria-describedby="temperature-dialog-description"
      >
        <form onSubmit={handleSubmit(handleSubmitTemperature)}>
          <DialogTitle id="temperature-dialog-title">
            Record Temperature
          </DialogTitle>
          <Typography className={classes.childTemperatureLabel}>
            for {childForTemperature ? childForTemperature.name : ""}
          </Typography>
          <DialogContent>
            <DialogContentText id="temperature-dialog-description">
              <BasicTextInput
                className={classes.m0}
                id="outlined-temperature-input"
                label="Temperature"
                type="text"
                name="temperature"
                placeholder="98.2"
                variant="outlined"
                fullWidth
                error={!!errors.temperature}
                ref={register({ required: true })}
                helperText={errors.temperature && "Required"}
              />

              {teachers.length > 0 && (
                <BasicSelect
                  className={classes.m0}
                  control={control}
                  rules={{ required: true }}
                  error={!!errors.teacherId}
                  defaultValue={getCurrentTeacher()}
                  name="teacherId"
                  label="Taken By"
                  labelId="teacher-taken-by-label"
                  selectId="teacher-taken-by-select"
                  options={teachers.map((t) => ({
                    label: `${t.firstName} ${t.lastName}`,
                    value: t.id,
                  }))}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit" autoFocus>
              Record
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ChildCheckin;
