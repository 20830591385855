import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { useDispatch, useSelector } from "react-redux";
import { selectChildren, createChild, deleteChild } from "./teacher";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "react-hook-form";
import BasicTextInput from "../common/components/BasicTextInput";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { alphabeticSort } from "../../services/utils";
import SearchFilter from "../common/components/SearchFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  createButton: {
    position: "fixed",
    bottom: "64px",
    right: theme.spacing(2),
  },
  m0: {
    marginBottom: 0,
  },
  table: {
    marginBottom: theme.typography.pxToRem(46),
  },
  filterBox: {
    margin: `0 auto`,
    marginTop: theme.spacing(3),
  },
}));

function Children() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = useState(false);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [filterText, setFilterText] = useState("");

  const children = useSelector(selectChildren);

  const handleNewClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAreYouSureClose = () => {
    setIdForDelete(null);
    setAreYouSureOpen(false);
  };

  const handleDeleteButtonClick = (id) => {
    setIdForDelete(id);
    setAreYouSureOpen(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteChild(idForDelete));
    setAreYouSureOpen(false);
    setIdForDelete(null);
  };

  const handleCreateClick = (data) => {
    dispatch(createChild(data));
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <SearchFilter
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
          className={classes.filterBox}
          placeholder="Find Child"
          onClear={() => setFilterText("")}
        />
        <Table className={classes.table} aria-label="children management table">
          <TableHead>
            <TableRow>
              <TableCell>Child Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {children
              .slice()
              .filter(
                (c) =>
                  c.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1
              )
              .sort(alphabeticSort("name"))
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleDeleteButtonClick(row.id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>

      <Fab
        onClick={handleNewClick}
        color="primary"
        className={classes.createButton}
        variant="extended"
      >
        <AddIcon />
        Child
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="children-dialog-title"
        aria-describedby="children-dialog-description"
      >
        <form onSubmit={handleSubmit(handleCreateClick)}>
          <DialogTitle id="children-dialog-title">Add Child</DialogTitle>
          <DialogContent>
            <DialogContentText id="children-dialog-description">
              <Grid container>
                <Grid item xs={12}>
                  <BasicTextInput
                    className={classes.m0}
                    id="outlined-full-name-input"
                    label="Full Name"
                    type="text"
                    name="name"
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                    ref={register({ required: true })}
                    helperText={errors.name && "Required"}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit" autoFocus>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={areYouSureOpen}
        onClose={handleAreYouSureClose}
        aria-labelledby="children-delete-dialog-title"
        aria-describedby="children-delete-dialog-description"
      >
        <DialogTitle id="children-delete-dialog-title">
          Remove Child
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="children-delete-dialog-description">
            Are you sure you want to remove this child? This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAreYouSureClose} color="default">
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleConfirmDelete}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Children;
