import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { Controller } from "react-hook-form";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2), width: "100%" },
}));

function BasicSelect({
  labelId,
  selectId,
  label,
  multiple = false,
  name,
  control,
  error,
  options = [],
  defaultValue,
  rules,
  className = "",
}) {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classnames(classes.root, className)}
      error={error}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
        render={({ onChange, value, onBlur }) => (
          <Select
            name={name}
            multiple={multiple}
            labelId={labelId}
            id={selectId}
            label={label}
            onChange={(e) => onChange(e, label)}
            onBlur={onBlur}
            value={value}
            renderValue={(selected) => {
              if (multiple) {
                return selected
                  .map((s) => {
                    const found = options.find(
                      (o) => `${o.value || o}` === `${s}`
                    );
                    return found.label || found;
                  })
                  .join(", ");
              }
              const found = options.find(
                (o) => `${o.value || o}` === `${selected}`
              );
              return found.label || found;
            }}
          >
            {options.map((o) => {
              if (!multiple) {
                return (
                  <MenuItem key={o.label || o} value={o.value || o}>
                    {o.label || o}
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={o.label || o} value={o.value || o}>
                  <Checkbox
                    checked={value ? value.indexOf(`${o.value}`) > -1 : false}
                  />
                  <ListItemText primary={o.label || o} />
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      {!!error && <FormHelperText>Required</FormHelperText>}
    </FormControl>
  );
}

export default BasicSelect;
