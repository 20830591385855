import React, { useEffect, useState } from "react";
import "./App.scss";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { selectUser, me, selectIsLoading } from "./apps/common/slices/auth";
import UnauthenticatedApp from "./apps/unauthenticated/UnauthenticatedApp";
import TeacherApp from "./apps/teacher/TeacherApp";
import Navbar from "./apps/common/components/Navbar";
import SnackbarContainer from "./apps/common/components/SnackbarContainer";
import axios from "axios";
import { API_BASE_URL } from "./services/dataService";

function App() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const [slug, setSlug] = useState("");
  const [appName, setAppName] = useState("");

  // useEffect(() => {
  //   dispatch(me());
  // }, [dispatch]);

  useEffect(() => {
    const urlSlug = window.location.pathname.split("/")[1];
    setSlug(urlSlug);
    axios
      .get(`${API_BASE_URL}/v1/public/tenantname?slug=${urlSlug}`)
      .then((res) => {
        setAppName(res.data);
        dispatch(me(urlSlug));
      });
  }, [dispatch]);

  return (
    <>
      {slug && (
        <Router basename={`/${slug}`}>
          <main>
            <Navbar appName={appName} />
            {isLoading && <div>Loading...</div>}

            {!isLoading && !user && <UnauthenticatedApp slug={slug} />}

            {!isLoading &&
              user &&
              (user.roles.indexOf("teacher") > -1 ||
                user.roles.indexOf("admin")) > -1 && <TeacherApp />}
            {/* {!isLoading && user && user.roles.indexOf("admin") > -1 && (
              <AdminApp />
            )} */}
            <SnackbarContainer />
          </main>
        </Router>
      )}
      {!slug && <div>Loading...</div>}
    </>
  );
}

export default App;
