import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTeachers,
  selectStaffCovid,
  // selectStaffTemperatures,
  createStaffTemperature,
  fetchStaffTemperatures,
  createStaffCovidSubmission,
} from "./teacher";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "react-hook-form";
import BasicTextInput from "../common/components/BasicTextInput";
import BasicSelect from "../common/components/BasicSelect";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RefreshIcon from "@material-ui/icons/RefreshOutlined";
import CheckIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import { selectUser } from "../common/slices/auth";
import TeacherCovidForm from "./TeacherCovidForm";
import { alphabeticSort } from "../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "bold",
    textAlign: "center",
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },

  createButton: {
    position: "fixed",
    bottom: "64px",
    right: theme.spacing(2),
  },
  m0: {},
  successIcon: {
    color: theme.palette.success.dark,
    verticalAlign: "middle",
  },
  warningIcon: {
    color: theme.palette.warning.dark,
    verticalAlign: "middle",
  },
  vim: {
    verticalAlign: "middle",
  },
  takenBy: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
  },
  staffTemperatureLabel: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  table: {
    marginBottom: theme.typography.pxToRem(46),
  },
  successRow: {
    backgroundColor: "#e1f0e2",
  },
}));

function StaffCheckin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();
  const [open, setOpen] = useState(false);
  const [staffForTemperature, setStaffForTemperature] = useState(null);
  // const temperatureSubmissions = useSelector(selectStaffTemperatures);
  const staffCovidSubmissions = useSelector(selectStaffCovid);
  const teachers = useSelector(selectTeachers);
  const loggedInUser = useSelector(selectUser);
  const [covidDialogOpen, setCovidDialogOpen] = useState(false);

  // const handleTemperatureClick = (staff) => {
  //   setStaffForTemperature(staff);
  //   setOpen(true);
  // };

  const handleClose = () => {
    setStaffForTemperature(null);
    setOpen(false);
  };

  const handleCovidDialogClose = () => {
    setCovidDialogOpen(false);
  };

  const handleSubmitTemperature = (data) => {
    dispatch(
      createStaffTemperature({ ...data, teacherId: staffForTemperature.id })
    );
    setStaffForTemperature(null);
    setOpen(false);
  };

  const refresh = useCallback(() => {
    dispatch(fetchStaffTemperatures());
  }, [dispatch]);

  const getCurrentTeacher = () => {
    const currentTeacher = teachers.find(
      (t) =>
        t.firstName === loggedInUser.firstName &&
        t.lastName === loggedInUser.lastName
    );
    return currentTeacher ? currentTeacher.id : teachers[0].id;
  };

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.secondaryHeading}>
          {moment().format("dddd, MMMM DD, YYYY")}
        </Typography>
        <Table className={classes.table} aria-label="check in management table">
          <TableHead>
            <TableRow>
              <TableCell>Staff Name</TableCell>
              <TableCell align="center">Signed In</TableCell>
              {/* <TableCell align="center">Temperature</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.length > 0 &&
              teachers.length > 0 &&
              teachers
                .slice()
                .sort(alphabeticSort("firstName"))
                .map((row) => (
                  <TableRow
                    key={row.id}
                    className={
                      // !!temperatureSubmissions[row.id] &&
                      !!staffCovidSubmissions[row.id] ? classes.successRow : ""
                    }
                  >
                    <TableCell component="th" scope="row">
                      {row.firstName} {row.lastName}
                    </TableCell>
                    <TableCell align="center">
                      {!!staffCovidSubmissions[row.id] && (
                        <CheckIcon className={classes.successIcon} />
                      )}
                      {!staffCovidSubmissions[row.id] && (
                        <>
                          {loggedInUser.userId !== row.id && (
                            <WarningIcon className={classes.warningIcon} />
                          )}
                          {loggedInUser.userId === row.id && (
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              onClick={() => setCovidDialogOpen(true)}
                            >
                              My COVID Form
                            </Button>
                          )}
                        </>
                      )}
                    </TableCell>
                    {/* <TableCell align="center">
                      {!!temperatureSubmissions[row.id] && (
                        <>
                          <span className={classes.vim}>
                            {temperatureSubmissions[row.id].temperature}&#8457;
                          </span>
                          <div className={classes.takenBy}>
                            Taken by{" "}
                            {
                              teachers.find(
                                (t) =>
                                  t.id ===
                                  temperatureSubmissions[row.id].takenById
                              ).firstName
                            }
                          </div>
                        </>
                      )}
                      {!temperatureSubmissions[row.id] && (
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => handleTemperatureClick(row)}
                        >
                          Record
                        </Button>
                      )}
                    </TableCell> */}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>

      <Fab
        onClick={refresh}
        color="primary"
        className={classes.createButton}
        variant="extended"
      >
        <RefreshIcon />
        Refresh
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="temperature-staff-dialog-title"
        aria-describedby="temperature-staff-dialog-description"
      >
        <form onSubmit={handleSubmit(handleSubmitTemperature)}>
          <DialogTitle id="temperature-staff-dialog-title">
            Record Temperature
          </DialogTitle>
          <Typography className={classes.staffTemperatureLabel}>
            for{" "}
            {staffForTemperature
              ? `${staffForTemperature.firstName} ${staffForTemperature.lastName}`
              : ""}
          </Typography>
          <DialogContent>
            <DialogContentText id="temperature-staff-dialog-description">
              <BasicTextInput
                className={classes.m0}
                id="outlined-staff-temperature-input"
                label="Temperature"
                type="text"
                name="temperature"
                placeholder="98.2"
                variant="outlined"
                fullWidth
                error={!!errors.temperature}
                ref={register({ required: true })}
                helperText={errors.temperature && "Required"}
              />

              {teachers.length > 0 && (
                <BasicSelect
                  className={classes.m0}
                  control={control}
                  rules={{ required: true }}
                  error={!!errors.takenById}
                  defaultValue={getCurrentTeacher()}
                  name="takenById"
                  label="Taken By"
                  labelId="teacher-staff-taken-by-label"
                  selectId="teacher-staff-taken-by-select"
                  options={teachers.map((t) => ({
                    label: `${t.firstName} ${t.lastName}`,
                    value: t.id,
                  }))}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit" autoFocus>
              Record
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={covidDialogOpen}
        onClose={handleCovidDialogClose}
        aria-labelledby="staff-covid-dialog"
        aria-describedby="staff-covid-dialog-description"
      >
        <DialogTitle id="staff-covid-dialog">Covid Questionnaire</DialogTitle>
        <DialogContent>
          <DialogContentText id="staff-covid-dialog-description">
            <TeacherCovidForm
              onSubmit={({ acceptedText, covidContentId }) =>
                () => {
                  const dataToSend = {
                    acceptedText,
                    covidContentId,
                  };
                  dispatch(createStaffCovidSubmission(dataToSend));
                  setCovidDialogOpen(false);
                }}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default StaffCheckin;
