import React, { useState } from "react";
import { useForm } from "react-hook-form";
import BasicTextInput from "../components/BasicTextInput";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_BASE_URL } from "../../../services/dataService";
import { useDispatch } from "react-redux";
import { addSnackbar } from "../slices/snackbar";

function ChangePasswordForm({ className }) {
  const { register, handleSubmit, errors, reset } = useForm();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <form
      className={className}
      onSubmit={handleSubmit(async (data) => {
        setLoading(true);

        try {
          await axios.put(`${API_BASE_URL}/v1/auth/password`, data);
          setLoading(false);
          dispatch(addSnackbar({ message: "Password changed" }));
          reset();
        } catch (e) {
          dispatch(
            addSnackbar({ message: e.response.data.message, severity: "error" })
          );
          setLoading(false);
        }
      })}
    >
      <BasicTextInput
        id="outlined-currentPassword-input"
        label="Current Password"
        type="password"
        name="currentPassword"
        autoComplete="current-password"
        variant="outlined"
        fullWidth
        error={!!errors.currentPassword}
        ref={register({ required: true, minLength: 7 })}
        helperText={errors.email && "Minimul length of 7 characters required"}
      />
      <BasicTextInput
        id="outlined-newPassword-input"
        label="New Password"
        type="password"
        name="newPassword"
        variant="outlined"
        fullWidth
        error={!!errors.newPassword}
        ref={register({ required: true, minLength: 7 })}
        helperText={
          errors.newPassword && "Minimul length of 7 characters required"
        }
      />
      <BasicTextInput
        id="outlined-newConfirmPassword-input"
        label="Confirm New Password"
        type="password"
        name="newConfirmPassword"
        variant="outlined"
        fullWidth
        error={!!errors.newConfirmPassword}
        ref={register({ required: true, minLength: 7 })}
        helperText={
          errors.newConfirmPassword && "Minimul length of 7 characters required"
        }
      />

      <Button
        style={{ width: "100%" }}
        variant="contained"
        type="submit"
        color="primary"
        disabled={loading}
      >
        Submit
      </Button>
    </form>
  );
}

export default ChangePasswordForm;
